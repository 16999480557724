import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import PageTransition from '../components/PageTransition';

const IconUsage = () => {
  const navigate = useNavigate();

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-500 flex flex-col items-center p-8 text-white">
        <motion.h1
          className="text-4xl font-bold mb-8 text-center"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          How to Apply Your Custom iOS Icons
        </motion.h1>

        <div className="max-w-3xl w-full">
          {/* Step 1: Download Your Icons */}
          <section className="mb-12">
            <motion.h2
              className="text-2xl font-semibold mb-4"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              1. Download Your Icons
            </motion.h2>
            <p className="mb-4">
              Begin by downloading your custom icons. Click the "Download" button on the preview page to save your icons as PNG files to your device.
            </p>
            <div className="flex justify-center">
              {/* Replace the src with your actual screenshot */}
              <img
                src="/screenshots/download-icons-ios.png"
                alt="Downloading Icons on iOS"
                className="w-full max-w-md rounded-lg shadow-lg"
              />
            </div>
          </section>

          {/* Step 2: Open the Shortcuts App */}
          <section className="mb-12">
            <motion.h2
              className="text-2xl font-semibold mb-4"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              2. Open the Shortcuts App
            </motion.h2>
            <p className="mb-4">
              Launch the <strong>Shortcuts</strong> app on your iOS device. This app allows you to create custom shortcuts that can be added to your home screen.
            </p>
            <div className="flex justify-center">
              {/* Replace the src with your actual screenshot */}
              <img
                src="/screenshots/open-shortcuts-app-ios.png"
                alt="Opening Shortcuts App on iOS"
                className="w-full max-w-md rounded-lg shadow-lg"
              />
            </div>
          </section>

          {/* Step 3: Create a New Shortcut to Open an App */}
          <section className="mb-12">
            <motion.h2
              className="text-2xl font-semibold mb-4"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              3. Create a New Shortcut
            </motion.h2>
            <p className="mb-4">
              Follow these steps to create a shortcut that opens your desired app with your custom icon:
            </p>
            <ol className="list-decimal list-inside mb-4 space-y-2">
              <li>Tap the <strong>+</strong> button in the top right corner to create a new shortcut.</li>
              <li>Select <strong>"Add Action"</strong>.</li>
              <li>In the search bar, type and select <strong>"Open App"</strong>.</li>
              <li>Tap <strong>"Choose"</strong> and select the app you want to customize.</li>
              <li>Tap the three dots (<strong>...</strong>) in the top right corner.</li>
              <li>Select <strong>"Add to Home Screen"</strong>.</li>
              <li>Tap the icon next to the shortcut name, then <strong>"Choose Photo"</strong> to select your custom icon.</li>
              <li>Adjust the name if desired, then tap <strong>"Add"</strong> to place the shortcut on your home screen.</li>
            </ol>
            <div className="flex justify-center">
              {/* Replace the src with your actual screenshot */}
              <img
                src="/screenshots/create-shortcut-ios.png"
                alt="Creating Shortcut on iOS"
                className="w-full max-w-md rounded-lg shadow-lg"
              />
            </div>
          </section>

          {/* Step 4: Add the Shortcut to Your Home Screen */}
          <section className="mb-12">
            <motion.h2
              className="text-2xl font-semibold mb-4"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              4. Add to Home Screen
            </motion.h2>
            <p className="mb-4">
              Once you've customized the shortcut with your icon, add it to your home screen:
            </p>
            <ol className="list-decimal list-inside mb-4 space-y-2">
              <li>After selecting your custom icon, tap <strong>"Add"</strong>.</li>
              <li>The shortcut will appear on your home screen with your chosen icon.</li>
              <li>Optionally, remove the original app icon to avoid duplication. (Note: The app remains accessible via the Shortcuts app or Spotlight Search.)</li>
            </ol>
            <div className="flex justify-center">
              {/* Replace the src with your actual screenshot */}
              <img
                src="/screenshots/add-shortcut-home-ios.png"
                alt="Adding Shortcut to Home Screen on iOS"
                className="w-full max-w-md rounded-lg shadow-lg"
              />
            </div>
          </section>
        </div>

        <motion.button
          onClick={() => navigate(-1)}
          className="mt-8 bg-white text-purple-600 font-bold py-2 px-6 rounded-full flex items-center hover:bg-purple-100 transition duration-300 ease-in-out"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <ArrowLeft className="mr-2" size={20} />
          Go Back
        </motion.button>
      </div>
    </PageTransition>
  );
};

export default IconUsage;
