/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link, json } from 'react-router-dom';
import { motion } from 'framer-motion';
import PageTransition from '../components/PageTransition';
import LoadingSpinner from '../components/LoadingSpinner';
import { Download, ArrowLeft, RefreshCw, Info } from 'lucide-react';
import { useApi } from '../hooks/useApi';
const SetPreview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [icons, setIcons] = useState([]);
  const [iconSet, setIconSet] = useState(null);
  const [error, setError] = useState(null);
  const [generatingIcons, setGeneratingIcons] = useState({});
  const [credits, setCredits] = useState(0);
  const { id: iconSetId } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApi();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [iconsResponse, creditsResponse] = await Promise.all([
          apiCall(`api/icons?iconSetId=${iconSetId}`),
          apiCall('api/credits')
        ]);
        
        if (!iconsResponse.ok || !creditsResponse.ok) {
          throw new Error('Failed to fetch data');
        }
        
        const iconsData = await iconsResponse.json();
        const creditsData = await creditsResponse.json();

        iconsData.iconSet.apps = JSON.parse(iconsData.iconSet.apps);
        console.log(iconsData.iconSet.apps)
        
        setIcons(iconsData.icons);
        setIconSet(iconsData.iconSet);
        setCredits(creditsData.credits);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [iconSetId]);

  const handleContinue = () => {
    navigate('/dashboard');
  };

  const handleDownload = async () => {
    try {
      const response = await apiCall(`api/download_icons?iconSetId=${iconSetId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch icon data');
      }
      
      const icons = await response.json();
      
      if (icons.length === 0) {
        console.log('No icons found to download');
        return;
      }
  
      for (const icon of icons) {
        try {
          const imageResponse = await fetch(icon.url);
          if (!imageResponse.ok) {
            throw new Error(`Failed to download image: ${icon.filename}`);
          }
          const blob = await imageResponse.blob();
          
          // For web browsers
          if (typeof window !== 'undefined' && window.navigator.msSaveBlob) {
            // For IE and Edge
            window.navigator.msSaveBlob(blob, icon.filename);
          } else {
            // For other browsers
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = icon.filename;
            link.click();
            URL.revokeObjectURL(link.href);
          }
  
          // For React Native or other mobile frameworks, you would use a different method here
          // For example:
          // await CameraRoll.save(icon.url, { type: 'photo' });
  
          console.log(`Downloaded: ${icon.filename}`);
        } catch (error) {
          console.error(`Error downloading ${icon.filename}:`, error);
        }
      }
  
      console.log('All downloads completed');
    } catch (err) {
      console.error('Error initiating icon download:', err);
    }
  };

  const handleGenerateIcon = async (appName) => {
    if (credits <= 0) {
      window.alert("You don't have enough credits to generate an icon.");
      return;
    }

    const confirmed = window.confirm(`Do you want to generate the icon for "${appName}"? You have ${credits} credit(s) left.`);
    if (confirmed) {
      setGeneratingIcons(prev => ({ ...prev, [appName]: true }));
      try {
        if (!iconSet) {
          throw new Error('Icon set not found');
        }

        const response = await apiCall('api/generate_icon', {
          method: 'POST',
          body: JSON.stringify({
            app_name: appName,
            app_style: iconSet.app_style,
            concept: iconSet.concept,
            icon_type: iconSet.icon_type,
            random_seed: iconSet.random_seed,
            iconSetId: iconSetId,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate icon');
        }

        const icon_url = await response.json();
        console.log('icon_url', icon_url);

        const [iconsResponse, creditsResponse] = await Promise.all([
          apiCall(`api/icons?iconSetId=${iconSetId}`),
          apiCall('api/credits')
        ]);

        const iconsData = await iconsResponse.json();
        const creditsData = await creditsResponse.json();
        
        setIcons(iconsData.icons);
        setCredits(creditsData.credits);
      } catch (err) {
        window.alert(`Error: ${err.message}`);
      } finally {
        setGeneratingIcons(prev => ({ ...prev, [appName]: false }));
      }
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 flex justify-center items-center text-white">
        <h2 className="text-2xl">Error: {error}</h2>
      </div>
    );
  }

  const appsWithIcons = iconSet.apps.map((appName) => {
    const icon = icons.find((icon) => icon.app_name === appName.trim());
    return { appName: appName.trim(), icon };
  });

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 flex flex-col justify-center items-center p-4 text-white">
        <motion.div
          className="text-center mb-12"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          <h2 className="text-5xl font-bold mb-4">{iconSet.name}</h2>
          <p className="text-xl mb-4 max-w-2xl mx-auto">
            Behold your personalized icon set! Each icon is a unique piece of art, tailored to your theme.
          </p>
          <p className="text-lg mb-4">
            You have <span className="font-bold text-yellow-300">{credits} credit(s)</span> remaining.
          </p>
          <p>
            If you want to generate another app icon, just click on it!
          </p>
        </motion.div>
        <motion.div
          className="grid grid-cols-4 gap-6 mb-12"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          {appsWithIcons.map(({ appName, icon }) => (
            <motion.div
              key={appName}
              className="flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div
                className="w-24 h-24 bg-white rounded-2xl overflow-hidden shadow-lg flex items-center justify-center cursor-pointer mb-2"
                onClick={() => {
                  if (!icon && !generatingIcons[appName]) {
                    handleGenerateIcon(appName);
                  }
                }}
              >
                {icon ? (
                  <img src={icon.icon_url} alt={icon.app_name} className="w-full h-full object-cover" />
                ) : generatingIcons[appName] ? (
                  <RefreshCw className="animate-spin text-purple-600" size={32} />
                ) : (
                  <div className="text-gray-500 text-center">
                    <p className="text-sm">(Click to generate)</p>
                  </div>
                )}
              </div>
              <p className="text-sm text-center">{appName}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="flex space-x-4">
          <motion.button
            className="bg-white text-purple-600 font-bold py-3 px-6 rounded-full text-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            <Link to="/dashboard" className="flex items-center justify-center w-full">
              <ArrowLeft className="mr-2" size={18} />
              Back to Dashboard
            </Link>
          </motion.button>
          <motion.button
            onClick={handleDownload}
            className="bg-purple-600 text-white font-bold py-3 px-6 rounded-full text-lg hover:bg-purple-700 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            <Download className="mr-2 inline" size={18} />
            Download
          </motion.button>
          
        </div>
        <motion.button
            onClick={() => navigate('/icon_usage')}
            className="mt-6 bg-white text-purple-600 font-bold py-3 px-6 rounded-full text-lg hover:bg-purple-700 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            {/* add some icon */}
            <Info className="mr-2 inline" size={18} />
            See How to Set Up Icons
          </motion.button>
        <motion.p
          className="mt-8 text-lg text-center max-w-2xl"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          Love what you see? Continue to customize your entire iOS experience with these stunning icons!
        </motion.p>
      </div>
    </PageTransition>
  );
};

export default SetPreview;