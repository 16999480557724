/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Plus, CreditCard, Star, Download } from 'lucide-react';
import { Link } from 'react-router-dom';
import PageTransition from '../components/PageTransition';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../contexts/AuthContext';

const Dashboard = () => {
  const [iconSets, setIconSets] = useState([]);
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { apiCall } = useApi();
  const { logout } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      console.log('initial fetchData');

      try {
        const [iconSetResponse, creditsResponse] = await Promise.all([
          apiCall('api/icon-sets'),
          apiCall('api/credits'),
        ]);
  
        if (iconSetResponse.ok) {
          const iconSetData = await iconSetResponse.json();
          setIconSets(iconSetData.results);
        }
  
        if (creditsResponse.ok) {
          const creditsData = await creditsResponse.json();
          setCredits(creditsData.credits);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    // Run only once on component mount
  }, []); // Removed `apiCall` dependency
  
  useEffect(() => {
    const fetchFirstIcons = async () => {
      const updatedSets = await Promise.all(
        iconSets.map(async (set) => {
          try {
            const iconResponse = await apiCall(`api/icons?iconSetId=${set.id}&limit=1`);
            if (iconResponse.ok) {
              const iconData = await iconResponse.json();
              const firstIcon = iconData.icons[0];
              console.log('firstIcon', firstIcon);
              return { ...set, firstIcon };
            }
          } catch (error) {
            console.error(`Error fetching first icon for set ${set.id}:`, error);
          }
          return set;
        })
      );
      setIconSets(updatedSets);
    };
    console.log('iconSets', iconSets);
    console.log('iconSets.length', iconSets.length);
    console.log('iconSets.some(set => set.firstIcon)', iconSets.some(set => set.firstIcon));
    if (iconSets.length > 0 && !iconSets.some(set => set.firstIcon)) {
      fetchFirstIcons();
    }
  }, [iconSets.length, apiCall]); // Depend on length only and check for firstIcon presence
  
  const handleBuyCredits = async () => {
    setIsLoading(true);
    try {
      const response = await apiCall('api/generate_checkout_session', { method: 'POST' });
      if (response.ok) {
        const data = await response.json();
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        } else {
          throw new Error('No redirect URL provided');
        }
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate checkout session');
      }
    } catch (error) {
      console.error('Error generating checkout session:', error);
      if (error.message === 'Your session has expired. Please log in again.') {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 p-8">
        <div className="max-w-6xl mx-auto">
          <motion.h1
            className="text-4xl font-bold text-white mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Your Icon Dashboard
          </motion.h1>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <motion.div
              className="bg-white rounded-xl shadow-lg p-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <h2 className="text-2xl font-semibold text-purple-600 mb-4">Credits Left</h2>
              <p className="text-4xl font-bold text-purple-800">{credits}</p>
              <p className="text-sm text-gray-600 mt-2">Each icon generation costs 1 credit</p>
            </motion.div>

            <motion.div
              className="bg-white rounded-xl shadow-lg p-6 flex items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <button
                className={`flex items-center px-6 py-3 bg-purple-600 text-white font-bold rounded-full hover:bg-purple-700 transition duration-300 ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onClick={handleBuyCredits}
                disabled={isLoading}
              >
                <CreditCard className="mr-2" size={24} />
                {isLoading ? 'Processing...' : 'Buy More Credits'}
              </button>
            </motion.div>

            <motion.div
              className="bg-white rounded-xl shadow-lg p-6 flex items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Link
                to="/upload"
                className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 text-white font-bold rounded-full hover:from-pink-600 hover:to-purple-700 transition duration-300"
              >
                <Star className="mr-2" size={24} />
                Generate New Set
              </Link>
            </motion.div>
          </div>

          <motion.h2
            className="text-3xl font-bold text-white mb-6"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            Your Icon Sets
          </motion.h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {iconSets.map((set) => (
              <motion.div
                key={set.id}
                className="bg-white rounded-xl shadow-lg overflow-hidden"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <div className="relative w-full h-48 bg-black rounded-t-xl overflow-hidden">
                  <div className="absolute inset-1 rounded-t-lg overflow-hidden bg-white">
                    {set.firstIcon ? (
                      <img src={set.firstIcon.icon_url} alt={`First icon from ${set.name}`} className="w-full h-full object-cover" />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-gray-200">
                        <span className="text-gray-400 text-lg">No icons yet</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-purple-800 mb-2">{set.name}</h3>
                  <p className="text-gray-600 mb-4">Created: {new Date(set.created_at).toLocaleDateString()}</p>
                  <motion.button
                    className="flex items-center justify-center w-full bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Download className="mr-2" size={18} />
                    Download
                  </motion.button>

                  <Link to={`/icon_set/${set.id}`} className="flex items-center justify-center w-full">
                    <motion.button
                      className="flex items-center justify-center w-full bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300 mt-4"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Plus className="mr-2" size={18} />
                      View Set Details
                    </motion.button>
                  </Link>
                </div>
              </motion.div>
            ))}

            <motion.div
              className="bg-gray-100 rounded-xl shadow-lg overflow-hidden flex items-center justify-center cursor-pointer hover:bg-gray-200 transition duration-300"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.7 }}
            >
              <Link to="/upload" className="flex flex-col items-center p-6">
                <Plus size={48} className="text-purple-600 mb-4" />
                <span className="text-xl font-semibold text-purple-800">Create New Set</span>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default Dashboard;
