import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, ArrowRight, Check, Loader } from 'lucide-react';
import PageTransition from '../components/PageTransition';
import { useAuth } from '../contexts/AuthContext';

import { useNavigate } from 'react-router-dom';

const EmailSignup = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState('email');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const { login } = useAuth();
  

  const navigate = useNavigate();

  const handleRequestOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      
      const response = await fetch('/api/request-otp', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setStep('otp');
      } else {
        setError(data.error || 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('/api/verify-otp', {
        method: 'POST',
        body: JSON.stringify({ email, otp }),
      });

      if (response.ok) {
        await login(); // Make sure login is an async function
        setStep('success');
        navigate('/dashboard');
      } else {
        const data = await response.json();
        setError(data.error || 'Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error during OTP verification:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-blue-400 via-purple-500 to-pink-500 flex flex-col justify-center items-center p-8 text-white">
        <motion.div
          className="max-w-md w-full bg-white bg-opacity-20 p-8 rounded-2xl shadow-2xl"
          initial="hidden"
          animate="visible"
          variants={variants}
        >
          <motion.h1 
            className="text-4xl font-bold mb-6 text-center"
            variants={variants}
          >
            {step === 'email' ? "Let's Get Started!" : step === 'otp' ? "Enter OTP" : "Welcome Aboard!"}
          </motion.h1>
          <motion.p
            className="text-lg text-center mb-4"
            variants={variants}
          >
            Log in with your email to get started or sign up for an account. New users get 5 free credits to generate icons!
          </motion.p>

          <AnimatePresence mode="wait">
            {step === 'email' && (
              <motion.form 
                onSubmit={handleRequestOTP}
                className="space-y-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="relative">
                  <Send className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-300" />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email address"
                    className="w-full py-3 pl-12 pr-4 bg-white bg-opacity-20 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-300 text-white placeholder-purple-200"
                    required
                  />
                </div>
                <motion.button
                  type="submit"
                  className="w-full bg-white text-purple-600 font-bold py-3 px-6 rounded-lg text-lg hover:bg-purple-100 transition duration-300 ease-in-out flex items-center justify-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader className="animate-spin" /> : (
                    <>
                      Email One Time Password <ArrowRight className="ml-2" size={20} />
                    </>
                  )}
                </motion.button>
              </motion.form>
            )}

            {step === 'otp' && (
              <motion.form 
                onSubmit={handleVerifyOTP}
                className="space-y-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="relative">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="w-full py-3 px-4 bg-white bg-opacity-20 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-300 text-white placeholder-purple-200"
                    required
                  />
                </div>
                <motion.button
                  type="submit"
                  className="w-full bg-white text-purple-600 font-bold py-3 px-6 rounded-lg text-lg hover:bg-purple-100 transition duration-300 ease-in-out flex items-center justify-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader className="animate-spin" /> : (
                    <>
                      Verify <ArrowRight className="ml-2" size={20} />
                    </>
                  )}
                </motion.button>
              </motion.form>
            )}

            {step === 'success' && (
              <motion.div
                className="text-center"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              >
                <Check className="mx-auto mb-4 text-green-400" size={48} />
                <h2 className="text-2xl font-bold mb-4">Successfully Logged In!</h2>
                <p>Redirecting you to the icons...</p>
              </motion.div>
            )}
          </AnimatePresence>

          {error && (
            <motion.p 
              className="mt-4 text-red-300 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {error}
            </motion.p>
          )}
        </motion.div>
      </div>
    </PageTransition>
  );
};

export default EmailSignup;