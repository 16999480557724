// src/hooks/useApi.js

import { useAuth } from '../contexts/AuthContext';

export const useApi = () => {
  const {checkAuthStatus, logout } = useAuth();

  const apiCall = async (endpoint, options = {}) => {
    const currentUrl = window.location.origin;
    const url = `${currentUrl}/${endpoint}`;

    const response = await fetch(url, {
      ...options,
      credentials: 'include',
    });

    if (response.status === 401) {
      // Token might be expired, try to refresh
      try {
        const refreshResult = await checkAuthStatus();
        if (refreshResult) {
          // Retry the original request
          return fetch(url, {
            ...options,
            credentials: 'include',
          });
        } else {
          throw new Error('Session expired');
        }
      } catch (error) {
        logout();
        throw new Error('Your session has expired. Please log in again.');
      }
    }

    return response;
  };

  return { apiCall };
};