import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import afterIcon from '../images/Clock_after.jpeg';
import beforeIcon from '../images/Clock_before.svg';
import avatar1 from '../images/avatar1.png';
import avatar2 from '../images/avatar2.png';
import avatar3 from '../images/avatar3.png';
import avatar4 from '../images/avatar4.png';

const HeroSection = () => {
  /**
   * Generates an array of star objects with randomized properties.
   * The stars are memoized to prevent regeneration on each render.
   */
  const isMobile = window.innerWidth <= 768;
  const stars = useMemo(() => {
    const count = 20; // Adjust the number of stars as needed
    return Array.from({ length: count }, (_, i) => ({
      key: i,
      initialX: `${Math.random() * 100}%`,
      initialY: `${Math.random() * 100}%`,
      size: Math.random() * 8 + 8, // Size between 8px and 16px
      // Relative movement percentages
      animateX: `${Math.random() * 6 - 3}%`, // -3% to +3%
      animateY: `${Math.random() * 6 - 3}%`, // -3% to +3%
      duration: Math.random() * 15 + 10, // Duration between 10s and 25s
    }));
  }, []);

  const avatars = [avatar1, avatar2, avatar3, avatar4];

  return (
    <section
      className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-purple-700 via-purple-600 to-purple-500"
      aria-labelledby="hero-heading"
    >
      {/* Subtle texture overlay */}
      <div
        className="absolute inset-0 opacity-5 bg-repeat"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'/%3E%3C/svg%3E")`,
        }}
        aria-hidden="true"
      ></div>

      {/* Animated stars */}
      {stars.map((star) => (
        <motion.div
          key={star.key}
          className="absolute text-yellow-200 opacity-70"
          style={{
            left: star.initialX,
            top: star.initialY,
          }}
          animate={{
            y: [0, star.animateY],
            x: [0, star.animateX],
          }}
          transition={{
            duration: star.duration,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
          }}
          aria-hidden="true"
        >
          <Star size={star.size} />
        </motion.div>
      ))}

      {/* Content */}
      <div className="relative text-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
        <motion.h1
          id="hero-heading"
          className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 sm:mb-8 leading-tight"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="text-white">Make Your</span>{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 via-yellow-400 to-orange-400 drop-shadow-lg">
            iPhone
          </span>{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-400 via-red-400 to-yellow-400 drop-shadow-lg">
            Uniquely
          </span>{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 drop-shadow-lg">
            Yours
          </span>
        </motion.h1>

        <motion.p
          className="text-xl sm:text-2xl md:text-3xl mb-8 sm:mb-10 leading-relaxed text-white drop-shadow-md max-w-3xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          Transform your iPhone's look in minutes with AI-powered custom app icons. Try it for free today!
        </motion.p>

        {/* Before and After Preview */}
        <motion.div
          className="flex flex-row justify-center items-center sm:space-y-0 sm:space-x-8 mb-10 sm:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <div className="text-center">
            <p className="text-white text-lg sm:text-xl mb-3">Before</p>
            <img
              src={beforeIcon}
              alt="iPhone before applying custom icons"
              className="w-24 h-24 sm:w-32 sm:h-32 rounded-2xl shadow-lg object-cover"
              loading="lazy"
            />
          </div>
          <ArrowRight className="mt-10 sm:mt-0 text-white" size={32} aria-hidden="true" />
          <div className="text-center">
            <p className="text-white text-lg sm:text-xl mb-3">After</p>
            <img
              src={afterIcon}
              alt="iPhone after applying custom icons"
              className="w-24 h-24 sm:w-32 sm:h-32 rounded-2xl shadow-lg object-cover"
              loading="lazy"
            />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.8 }}
          className="mb-10 sm:mb-12"
        >
          <Link
            to="/upload"
            className="inline-flex items-center justify-center px-8 py-4 sm:px-10 sm:py-5 bg-white text-purple-600 font-bold text-xl sm:text-2xl rounded-full hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl"
          >
            {isMobile ? 'Create Now For Free' : <>
            Create Your Custom Icons Now - Free Trial <ArrowRight className="ml-3" size={24} aria-hidden="true" />
            </>}
          </Link>
          <p className="mt-4 sm:mt-6 text-lg sm:text-xl text-white opacity-90 drop-shadow-sm">
            No design skills needed - Our AI does the magic! Start your free trial today.
          </p>
        </motion.div>

        {/* Social Proof */}
        <motion.div
          className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.8 }}
        >
          <p className="text-lg sm:text-xl text-white font-semibold">
            Trusted by 10,000+ users who started with a free trial
          </p>
          <div className="flex -space-x-3">
            {avatars.map((avatar, index) => (
              <img
                key={index}
                className="inline-block h-10 w-10 sm:h-12 sm:w-12 rounded-full ring-2 ring-white"
                src={avatar}
                alt={`User avatar ${index + 1}`}
                loading="lazy"
              />
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;
