import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload as UploadIcon, X, Check, ArrowRight } from 'lucide-react'; // Added Type icon for manual input
import PageTransition from '../components/PageTransition';
import LoadingSpinner from '../components/LoadingSpinner';

const Upload = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [inputMode, setInputMode] = useState('upload'); // 'upload' or 'manual'
  const [manualInput, setManualInput] = useState('');
  const [apps, setApps] = useState([]);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
    } else {
      alert('Please select a valid image file.');
    }
  };

  // Convert file to Base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle manual input change
  const handleManualInputChange = (e) => {
    const value = e.target.value;
    setManualInput(value);

    // Split input by commas and filter out empty strings
    const splitApps = value.split(',').map(app => app.trim()).filter(app => app.length > 0);
    setApps(splitApps);
  };

  // Handle app removal
  const removeApp = (index) => {
    const updatedApps = [...apps];
    updatedApps.splice(index, 1);
    setApps(updatedApps);
    setManualInput(updatedApps.join(', '));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputMode === 'upload' && !file) {
      alert('Please select a screenshot to upload.');
      return;
    }

    if (inputMode === 'manual' && apps.length === 0) {
      alert('Please enter at least one app name.');
      return;
    }

    setIsLoading(true);
    setUploadStatus('uploading');
    window.sa_event("upload_screen_apps", { mode: inputMode });

    try {
      let result;
      if (inputMode === 'upload') {
        // Handle image upload
        const base64Image = await convertFileToBase64(file);
        const response = await fetch(`/api/get_screen_apps`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ image_url: base64Image }),
        });

        if (response.ok) {
          result = await response.json();
          console.log(result.apps);
          sessionStorage.setItem('apps', JSON.stringify(result.apps));
        } else {
          throw new Error(response.statusText);
        }
      } else {
        // Handle manual input
        // Assuming the backend can handle manual app names
        const apps = manualInput.split(',').map(app => app.trim()).filter(app => app.length > 0);
        console.log(apps);
        sessionStorage.setItem('apps', JSON.stringify(apps));
       
      }

      setUploadStatus('success');
      setTimeout(() => navigate('/theme'), 1500);
    } catch (error) {
      window.sa_event("upload_screen_apps_error", { mode: inputMode });
      console.error('Error uploading data:', error);
      setUploadStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle input mode
  const toggleInputMode = (mode) => {
    setInputMode(mode);
    setFile(null);
    setManualInput('');
    setApps([]);
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-blue-400 via-indigo-500 to-purple-500 flex flex-col justify-center items-center p-4">
        <motion.h2 
          className="text-4xl font-bold text-white mb-6 text-center"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          Upload Your Home Screen or Enter Apps
        </motion.h2>
        <motion.p
          className="text-lg text-white text-center mb-8 max-w-md"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Let's start by seeing what apps you have. You can either upload a screenshot of your iPhone's home screen or manually type your apps to begin the customization journey!
        </motion.p>
        
        {/* Toggle Buttons */}
        <div className="flex space-x-4 mb-8">
          <motion.button
            onClick={() => toggleInputMode('upload')}
            className={`px-4 py-2 rounded-full font-semibold focus:outline-none transition-colors ${
              inputMode === 'upload' ? 'bg-white text-purple-600' : 'bg-purple-600 text-white hover:bg-purple-700'
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Upload Image
          </motion.button>
          <motion.button
            onClick={() => toggleInputMode('manual')}
            className={`px-4 py-2 rounded-full font-semibold focus:outline-none transition-colors ${
              inputMode === 'manual' ? 'bg-white text-purple-600' : 'bg-purple-600 text-white hover:bg-purple-700'
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Enter Apps
          </motion.button>
        </div>

        <motion.form 
          onSubmit={handleSubmit}
          className="space-y-6 w-full max-w-md"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <AnimatePresence mode="wait">
            {inputMode === 'upload' ? (
              !file ? (
                <motion.div 
                  className="flex items-center justify-center w-full"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-white border-dashed rounded-lg cursor-pointer bg-purple-600 bg-opacity-50 hover:bg-opacity-70 transition-all duration-300">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <UploadIcon className="w-12 h-12 mb-4 text-white" />
                      <p className="mb-2 text-sm text-white"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-200">PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>
                    <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept="image/*" />
                  </label>
                </motion.div>
              ) : (
                <motion.div 
                  className="relative"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <img src={URL.createObjectURL(file)} alt="Uploaded file" className="w-full h-64 object-cover rounded-lg shadow-lg" />
                  <motion.button
                    type="button"
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full shadow-md"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setFile(null)}
                  >
                    <X size={20} />
                  </motion.button>
                </motion.div>
              )
            ) : (
              <motion.div 
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <label htmlFor="manual-input" className="block text-white text-sm font-medium mb-2">
                  Enter your apps (separated by commas)
                </label>
                <input
                  id="manual-input"
                  type="text"
                  ref={inputRef}
                  value={manualInput}
                  onChange={handleManualInputChange}
                  className="w-full p-3 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-purple-400"
                  placeholder="e.g., Messages, Photos, Safari, Mail"
                />
                {/* Display Apps as Tags */}
                <div className="flex flex-wrap mt-2">
                  {apps.map((app, index) => (
                    <motion.div
                      key={index}
                      className="flex items-center bg-purple-600 text-white px-3 py-1 mr-2 mb-2 rounded-full shadow-md"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      layout
                    >
                      <span>{app}</span>
                      <X
                        className="ml-2 cursor-pointer"
                        size={16}
                        onClick={() => removeApp(index)}
                      />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          
          <AnimatePresence mode="wait">
            {uploadStatus === 'success' && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex items-center justify-center text-white bg-green-500 p-3 rounded-lg shadow-md"
              >
                <Check className="mr-2" /> Upload successful! Redirecting...
              </motion.div>
            )}
            {uploadStatus === 'error' && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex items-center justify-center text-white bg-red-500 p-3 rounded-lg shadow-md"
              >
                <X className="mr-2" /> Upload failed. Please try again.
              </motion.div>
            )}
          </AnimatePresence>
          
          <motion.button
            type="submit"
            className="w-full bg-white text-purple-600 font-bold py-4 px-6 rounded-full text-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading || (inputMode === 'upload' ? !file : apps.length === 0)}
          >
            {isLoading ? <LoadingSpinner /> : (
              <>
                {inputMode === 'upload' ? 'Upload & Continue' : 'Save & Continue'} <ArrowRight className="ml-2" size={24} />
              </>
            )}
          </motion.button>
        </motion.form>
      </div>
    </PageTransition>
  );
};

export default Upload;
