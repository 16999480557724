import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

const IconGrid = ({ isTransformed }) => (
  <div className="grid grid-cols-3 gap-2 p-4 bg-gray-800 rounded-2xl shadow-inner">
    {[...Array(9)].map((_, i) => (
      <motion.div
        key={i}
        className={`w-12 h-12 rounded-xl ${
          isTransformed ? 'bg-gradient-to-br from-pink-400 to-purple-500' : 'bg-gray-600'
        }`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      />
    ))}
  </div>
);

const TransformationPreview = () => (
  <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
    <div className="text-center">
      <IconGrid isTransformed={false} />
      <p className="mt-4 font-bold text-lg">Before</p>
    </div>
    <motion.div
      animate={{ x: [0, 10, 0] }}
      transition={{ repeat: Infinity, duration: 1.5 }}
    >
      <ArrowRight size={48} />
    </motion.div>
    <div className="text-center">
      <IconGrid isTransformed={true} />
      <p className="mt-4 font-bold text-lg">After</p>
    </div>
  </div>
);

export default TransformationPreview;