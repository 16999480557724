import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from './pages/Home';
import Upload from './pages/Upload';
import ThemeDescription from './pages/ThemeDescription';
import StyleDescription from './pages/StyleDescription';
import SetPreview from './pages/SetPreview';
import EmailSignup from './pages/EmailSignup';
import Dashboard from './pages/Dashboard';
import Preview from './pages/Preview';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import IconUsage from './pages/IconUsage';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/upload" element={<PrivateRoute><Upload /></PrivateRoute>} />
            <Route path="/theme" element={<PrivateRoute><ThemeDescription /></PrivateRoute>} />
            <Route path="/style" element={<PrivateRoute><StyleDescription /></PrivateRoute>} />
            <Route path="/preview" element={<PrivateRoute><Preview /></PrivateRoute>} />
            <Route path="/auth" element={<EmailSignup />} />
            <Route path="/icon_set/:id" element={<PrivateRoute><SetPreview /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/icon_usage" element={<PrivateRoute><IconUsage /></PrivateRoute>} />
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;